<template>
  <b-modal
    id="modalAdminPermission"
    title="Permission"
    @show="onShow()"
    @hide="resetModal()"
    size="lg"
    no-close-on-backdrop
    ok-only
    ok-title="Done"
    :visible="modalVisibility(modalId)"
  >
    <b-row>
      <!-- Admin Info -->
      <b-col>
        <KTCard title="Admin Info">
          <template v-slot:body>
            <b-row class="mb-2" align-v="center">
              <b-col sm="4">
                <label>ID</label>
              </b-col>
              <b-col>
                <b-input readonly :value="admin.user_id"></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2" align-v="center">
              <b-col sm="4">
                <label>Username</label>
              </b-col>
              <b-col>
                <b-input readonly :value="admin.username"></b-input>
              </b-col>
            </b-row>
            <b-row class="mb-2" align-v="center">
              <b-col sm="4">
                <label>Email</label>
              </b-col>
              <b-col>
                <b-input readonly :value="admin.email"></b-input>
              </b-col>
            </b-row>
          </template>
        </KTCard>
      </b-col>
      <!-- END Admin Info -->
    </b-row>
    <b-row>
      <!-- Admin Permission List -->
      <b-col>
        <KTCard title="Admin Permissions">
          <template v-slot:toolbar>
            <b-button-group size="sm">
              <b-button
                @click="assignGlobalAdminGeneralPermission()"
                variant="info"
                size="sm"
                ><i class="fa fa-plus"></i> Assign General Permission
              </b-button>
              <b-button @click="savePermission()" variant="primary" size="sm"
                ><i class="fa fa-plus"></i> Save</b-button
              >
            </b-button-group>
          </template>

          <template v-slot:body>
            <b-table
              class="table-vcenter"
              :items="AdminPermission"
              :fields="fields"
              :busy="isBusy"
              head-variant="light"
              show-empty
              outlined
              responsive
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(read_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="updatePermission($event, row.index, statusList.read)"
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(edit_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="updatePermission($event, row.index, statusList.edit)"
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(create_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.create)
                  "
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(delete_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.delete)
                  "
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(approve_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.approve)
                  "
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(maker_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.maker)
                  "
                  switch
                ></b-form-checkbox>
              </template>
              <template v-slot:cell(checker_status)="row">
                <b-form-checkbox
                  :checked="row.value ? true : false"
                  @change="
                    updatePermission($event, row.index, statusList.checker)
                  "
                  switch
                ></b-form-checkbox>
              </template>
            </b-table>
          </template>
        </KTCard>
      </b-col>
      <!-- END Admin Permission List -->
    </b-row>
  </b-modal>
</template>

<script>
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import {
  ASSIGN_GLOBAL_ADMIN_GENERAL_PERMISSION,
  GET_ADMIN_PERMISSION,
  UPDATE_ADMIN_PERMISSION,
} from "@/core/services/store/modules/superadmin/administration.module";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
  data() {
    return {
      modalId: "modalAdminPermission",
      isBusy: true,
      admin: {
        user_id: "",
        username: "",
        email: "",
      },
      permissions: [],
      fields: [
        { key: "module_name", label: "Module" },
        { key: "read_status", label: "Read" },
        { key: "edit_status", label: "Edit" },
        { key: "create_status", label: "Create" },
        { key: "delete_status", label: "Delete" },
        { key: "approve_status", label: "Approve" },
        { key: "maker_status", label: "Maker" },
        { key: "checker_status", label: "Checker" },
      ],
      statusList: {
        read: "read_status",
        edit: "edit_status",
        create: "create_status",
        delete: "delete_status",
        approve: "approve_status",
        maker: "maker_status",
        checker: "checker_status",
      },
    };
  },
  computed: {
    AdminPermission() {
      const permission = this.$store.state.superadmin_administration
        .adminPermission;
      console.log("AdminPermision ", permission);
      if (Array.isArray(permission) && permission.length) {
        return permission;
      } else {
        return [];
      }
    },
    // ModalAdminPermission() {
    //   return this.$store.state.ui.activeModal === "modal-admin-permission";
    // },
  },
  methods: {
    savePermission() {
      // save admin permission changes
      const payload = {
        user_id: this.admin.user_id,
        fullUpdatedList: this.permissions,
      };
      // SystemHelper.confirmationDialogHandler(
      //   { title: "CONFIRMATION", html: "Save admin permission?" },
      //   { action: UPDATE_ADMIN_PERMISSION, payload: payload },
      //   null,
      //   null
      // );
      //
      //

      this.$swal
        .fire({
          title: "Confirmation",
          showCancelButton: true,
          reverseButtons: true,
          allowOutsideClick: false,
          backdrop: true,
          heightAuto: false,
          confirmButtonText: "Confirm",
          html: "Update permission?",
          customClass: {
            container: "confirmation-swal",
            header: "bg-primary",
            confirmButton: "btn btn-sm btn-primary ml-3",
            cancelButton: "btn btn-sm btn-secondary ml-3",
            input: "form-control",
          },
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$store
              .dispatch(UPDATE_ADMIN_PERMISSION, payload)
              .then((res) => {
                if (res.response_code === 2100) {
                  this.$store
                    .dispatch(GET_ADMIN_PERMISSION, payload)
                    .then((res) => {
                      // const data = res.data;
                      if (res.response_code == 2100) {
                        // permissions placeholder
                        this.permissions = [];
                        this.AdminPermission.forEach((permission) => {
                          this.permissions.push(permission);
                        });
                        SystemHelper.successHandler(res, null, "success");
                      } else {
                        this.permissions = [];
                        SystemHelper.errorHandler(res, null, "error");
                      }
                    });
                } else {
                  console.log("UPDATE_ADMIN_PERMISSION FAILED");
                  SystemHelper.errorHandler(res, null, "error");
                }
              });
          }
        });

      // this.$store.dispatch(UPDATE_ADMIN_PERMISSION, payload).then((res) => {
      //   if (res.response_code === 2100) {
      //     console.log("UPDATE_ADMIN_PERMISSION SUCCESS");
      //   } else {
      //     console.log("UPDATE_ADMIN_PERMISSION FAILED");
      //   }
      // });
    },
    assignGlobalAdminGeneralPermission() {
      // save admin permission changes
      const payload = {
        user_id: this.admin.user_id,
      };
      // SystemHelper.confirmationDialogHandler(
      //   { title: "CONFIRMATION", html: "Save admin permission?" },
      //   { action: UPDATE_ADMIN_PERMISSION, payload: payload },
      //   null,
      //   null
      // );

      SystemHelper.confirmationDialogHandler(
        {
          title: "Confirmation",
          html: "Assign global admin general permission?",
        },
        { action: ASSIGN_GLOBAL_ADMIN_GENERAL_PERMISSION, payload: payload },
        [{ action: GET_ADMIN_PERMISSION, payload: payload }]
      );

      // this.$swal
      //   .fire({
      //     title: "Confirmation",
      //     showCancelButton: true,
      //     reverseButtons: true,
      //     allowOutsideClick: false,
      //     backdrop: true,
      //     heightAuto: false,
      //     confirmButtonText: "Confirm",
      //     html: "Assign global admin general permission?",
      //     customClass: {
      //       container: "confirmation-swal",
      //       header: "bg-primary",
      //       confirmButton: "btn btn-sm btn-primary ml-3",
      //       cancelButton: "btn btn-sm btn-secondary ml-3",
      //       input: "form-control",
      //     },
      //   })
      //   .then((result) => {
      //     /* Read more about isConfirmed, isDenied below */
      //     if (result.isConfirmed) {
      //       this.$store
      //         .dispatch(ASSIGN_GLOBAL_ADMIN_GENERAL_PERMISSION, payload)
      //         .then((res) => {
      //           if (res.response_code === 2100) {
      //             this.$store
      //               .dispatch(GET_ADMIN_PERMISSION, payload)
      //               .then((res) => {
      //                 // const data = res.data;
      //                 if (res.response_code == 2100) {
      //                   // permissions placeholder
      //                   this.permissions = [];
      //                   this.AdminPermission.forEach((permission) => {
      //                     this.permissions.push(permission);
      //                   });
      //                 } else {
      //                   this.permissions = [];
      //                   SystemHelper.errorHandler(res, null, "error");
      //                 }
      //               });
      //           } else {
      //             console.log("ASSIGN_GLOBAL_ADMIN_GENERAL_PERMISSION FAILED");
      //             SystemHelper.errorHandler(res, null, "error");
      //           }
      //         });
      //     }
      //   });
    },
    updatePermission(e, index, status) {
      const value = e ? 1 : 0;
      this.permissions[index][status] = value;
    },
    onShow() {
      console.log("showing");
      this.isBusy = true;
      // assign selected admin
      this.admin = this.$parent.selectedAdmin;

      // get admin permission
      const payload = {
        user_id: this.admin.user_id,
      };
      this.$store.dispatch(GET_ADMIN_PERMISSION, payload).then((res) => {
        // const data = res.data;
        if (res.response_code == 2100) {
          // permissions placeholder
          this.permissions = [];
          this.AdminPermission.forEach((permission) => {
            this.permissions.push(permission);
          });
        } else {
          this.permissions = [];
        }

        this.isBusy = false;
      });
    },
    resetModal() {
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
