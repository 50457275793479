<template>
  <b-modal
    ok-title="Submit"
    size="md"
    @hide="resetModal()"
    id="addGlobalAdminUserModal"
    title="Add Global Admin"
    hide-footer
    no-close-on-backdrop
    :visible="modalVisibility(modalId)"
  >
    <div>
      <b-row>
        <b-col>
          <base-input
            v-model="form.username"
            label="Username"
            :validator="$v.form.username"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            v-model="form.email"
            label="Email"
            :validator="$v.form.email"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-input
            v-model="form.phone_number"
            label="Phone Number"
            :validator="$v.form.phone_number"
            :messages="localMessages"
          ></base-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-password
            v-model="form.password"
            label="Password"
            :validator="$v.form.password"
            :messages="localMessages"
          ></base-password>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <base-password
            v-model="form.password_confirmation"
            label="Confirm Password"
            :validator="$v.form.password_confirmation"
            :messages="localMessages"
          ></base-password>
        </b-col>
      </b-row>
      <b-row class="mt-6">
        <b-col class="d-flex flex-row-reverse">
          <b-button @click="submitForm" class="ml-3" variant="primary">
            Submit
          </b-button>
          <b-button @click="resetModal" class="ml-3" variant="secondary">
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import {
  GET_GLOBAL_ADMIN_USER_LIST,
  REGISTER_GLOBAL_ADMIN,
} from "@/core/services/store/modules/superadmin/administration.module";
import { email, required, sameAs } from "vuelidate/lib/validators";
import SystemHelper from "@/core/services/systemhelper.service";

export default {
  data() {
    return {
      modalId: "addGlobalAdminUserModal",
      isBusy: true,
      form: {
        username: "",
        email: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  validations: {
    form: {
      username: { required },
      email: { required, email },
      phone_number: { required },
      password: { required },
      password_confirmation: { sameAsPassword: sameAs("password") },
    },
  },
  computed: {},
  methods: {
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      } else {
        console.log("submit");
        let payload = this.form;

        SystemHelper.confirmationDialogHandler(
          { title: "Confirmation", html: "Create Global Admin?" },
          { action: REGISTER_GLOBAL_ADMIN, payload: payload },
          [
            {
              action: GET_GLOBAL_ADMIN_USER_LIST,
              payload: this.defaultPayload,
            },
          ],
          this.modalId
        );
      }
    },
    resetModal() {
      this.$v.form.$reset();
      this.form = {
        username: "",
        email: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
      };
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
