<template>
  <div>
    <KTCard title="Global Admin List">
      <template v-slot:toolbar>
        <b-button
          @click="openModal('addGlobalAdminUserModal')"
          variant="primary"
          size="sm"
          ><i class="fa fa-plus"></i> Add Global Admin</b-button
        >
      </template>

      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          show-empty
          hover
          :items="globalAdminUserList"
          :fields="fields"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="onPermission(row.item)"
                variant="primary"
                size="sm"
              >
                Permissions
              </b-button>
            </b-button-group>
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <create-global-admin-modal></create-global-admin-modal>
    <admin-permission-modal></admin-permission-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import { GET_GLOBAL_ADMIN_USER_LIST } from "@/core/services/store/modules/superadmin/administration.module";

import { validationMixin } from "vuelidate";

import AdminPermissionModal from "./modals/AdminPermissionModal";
import CreateGlobalAdminModal from "@/view/pages/superadmin/modals/CreateGlobalAdminModal";

export default {
  name: "global-admin-page",
  mixins: [validationMixin],
  components: { AdminPermissionModal, CreateGlobalAdminModal },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Superadmin" },
      { title: "Global Admin List" },
    ]);

    let payload = {
      length: 100,
      start: 0,
    };

    this.$store.dispatch(GET_GLOBAL_ADMIN_USER_LIST, payload);
  },

  computed: {
    tableSettings() {
      const list =
        this.$store.state.superadmin_administration.globalAdminUserList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    globalAdminUserList() {
      const list =
        this.$store.state.superadmin_administration.globalAdminUserList;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      selectedAdmin: null,

      createAdminModalId: "addGlobalAdminUserModal",
      filter: "",
      currentPage: 1,
      fields: [
        {
          key: "user_id",
          label: "Id",
          sortable: true,
        },
        {
          key: "username",
          label: "Username",
          sortable: false,
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "created_at",
          label: "Created On",
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          headerTitle: "",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onPermission(admin) {
      // assign selected admin
      this.selectedAdmin = admin;

      // open permission modal
      this.openModal("modalAdminPermission");
    },
  },
};
</script>
